import React from 'react';
import {
    Button,
    Window as Windows95Window,
    WindowContent,
    WindowHeader
} from 'react95';

import Game from '../Game/Game';
import './window.scss';

export default function MainWindow ({ ...props })
{
    const [error, setError] = React.useState(false);
    const [shouldLoad, setShouldLoad] = React.useState(true);

    React.useEffect(() => {
        localStorage.setItem('shouldLoad', JSON.stringify(shouldLoad));
    }, [shouldLoad]);
    
    const handleReset = () => {
        setShouldLoad(false);
        window.location.reload();
    }

    return (
        <>
            <Windows95Window className="window">
                <WindowHeader active={!error} className="window-header">
                    <span>Z8 Water Treatment Plant Override</span>
                    <Button onClick={ () => { if (window.confirm('Are you sure you want to start again?')) { handleReset() } } }>
                        <span className="close-icon"/>
                    </Button>
                </WindowHeader>
                <WindowContent style={{ height: '520px'}}>
                    <Game setError={setError}/>
                </WindowContent>
            </Windows95Window>
            { error ?
                <Windows95Window className='window' style={{ position: 'fixed', top: '35%', right: 'calc(50% - 200px)', width: '400px', height: 'auto' }}>
                    <WindowHeader className='window-header'>
                        <span></span>
                        <Button onClick={ () => { setError(false) }}>
                            <span className='close-icon' />
                        </Button>
                    </WindowHeader>
                    <WindowContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{ textAlign: 'center' }}>
                            Oh non, il semblerait que nous manquions de temps. Pendant que vous étiez en train de travailler, je me suis arrangée pour pirater le compte à rebours. Il est suspendu pour l’instant, j’espère suffisamment longtemps pour désarmer l’appareil. Cela pourrait nous faire perdre la trace de Yakov, mais au moins, les Molvariens seront sauvés.
                        </p>
                        <Button onClick={ () => { setError(false) }} style={{ width: 'min-content', marginTop: '30px'}}>
                            Ok
                        </Button>
                    </WindowContent>
                </Windows95Window>
            : '' }
        </>
    )
}
