import React, { useMemo } from 'react';
import { Button, Fieldset, Panel, TextField } from 'react95';
import './play.scss';

import All from '../../Assets/All_Functional.gif';
import NetworkMotherboardPower from '../../Assets/network_motherboard_power.gif';
import MotherboardPower from '../../Assets/motherboard_power.gif';
import Power from '../../Assets/power.gif';
import AllDisabled from '../../Assets/all_disabled.gif';
import Defused from '../../Assets/defused.gif';

export default function Play ({ setError, initialState, ...props })
{
    const [messages, setMessages] = React.useState([]);
    const [inputText, setInputText] = React.useState("");
    const [step, setStep] = React.useState(1);
    const [inputEnabled, setInputEnabled] = React.useState(true);
    const [systemInformation, setSystemInformation] = React.useState("");
    const [rows] = React.useState(1);
    const [timerInterval, setTimerInterval] = React.useState(1);
    const [stopTimer, setStopTimer] = React.useState(false);
    const [timerFrozen, setTimerFrozen] = React.useState(false);
    const [image, setImage] = React.useState(All);
    const [components, setComponents] = React.useState(["Réseau", "Mémoire", "Carte mère", "Alimentation"])

    const timerDecrement = (state, action) => {
        if (action && (action.type === "setInitial")) {
            return action.payload;
        } else {
            return state - (1 + timePenalty);
        }
    }
    const [timePenalty, setTimePenalty] = React.useState(0);
    const [timer, timerDispatch] = React.useReducer(timerDecrement, 60 * 60)

    const userInput = React.createRef();

    // const setMessages = useCallback(obj => {
    //   setRealMessages(obj)
    //   // eslint-disable-next-line
    // }, [messages])

    React.useEffect(() => {
        localStorage.setItem('timer', JSON.stringify(timer));
        localStorage.setItem('messages', JSON.stringify(messages));
        localStorage.setItem('inputText', JSON.stringify(inputText));
        localStorage.setItem('step', JSON.stringify(step));
        localStorage.setItem('inputEnabled', JSON.stringify(inputEnabled));
        localStorage.setItem('systemInformation', JSON.stringify(systemInformation));
        localStorage.setItem('timerInterval', JSON.stringify(timerInterval));
        localStorage.setItem('stopTimer', JSON.stringify(stopTimer));
        localStorage.setItem('timerFrozen', JSON.stringify(timerFrozen));
        localStorage.setItem('image', JSON.stringify(image));
    }, [timer, messages, inputText, step, inputEnabled, systemInformation, timerInterval, stopTimer, timerFrozen, image]);

    React.useEffect(() => {
        console.log(initialState);
        if (Object.keys(initialState).length !== 0 && initialState.shouldLoad) {
            timerDispatch({ type: 'setInitial', payload: initialState.timer});
            setMessages(initialState.messages);
            setInputText(initialState.inputText);
            setStep(initialState.step);
            setSystemInformation(initialState.systemInformation);
            setTimerInterval(initialState.timerInterval);
            setStopTimer(initialState.stopTimer);
            setTimerFrozen(initialState.timerFrozen);
            setImage(initialState.image);
        } else {
            setMessages([
                {
                    you: false,
                    message: "Il semblerat qu’il y ait quatre modules sur l’appareil de dissémination, qui alimente tous la pompe principale. Si nous parvenons à les déconnecter tous les quatre, cela devrait rendre l’appareil hors d’usage."
                }
            ])
            setTimeout(() => {
                setTimeout(() => {
                    setMessages([
                        {
                            you: false,
                            message: "Il semblerat qu’il y ait quatre modules sur l’appareil de dissémination, qui alimente tous la pompe principale. Si nous parvenons à les déconnecter tous les quatre, cela devrait rendre l’appareil hors d’usage."
                        },
                        {
                            you: false,
                            message: `Il faudrait déterminer sur quel module agir en premier. Cette machine semble très complexe, je pense donc qu’il faut s’assurer de déconnecter les modules dans le bon order, pour éviter des conséquences catastrophiques.<br/><br/> Par quel module devons-nous commencer ? <br/> ${remainingComponentString}`
                        }
                    ])
                }, 1500);
            }, 1500);
        }
    // eslint-disable-next-line
    }, [initialState]);

    const handleKeyDown = (ev) => {
        if (ev.keyCode === 13 && inputEnabled) {
            sendMessage();
        }
    }

    const wireCutFailMessage = useMemo(() => {
        if (!timerFrozen) {
            return 'Oh non, apparemment, il s’agissait du mauvais câble… et le compte à rebours s’est accéléré. Réessayons, sans nous tromper cette fois.<br/><br/>Quel câble pensez-vous que je doive sectionner ?'
        } else {
            return 'Oh non, apparemment, il s’agissait du mauvais câble… Réessayons, sans nous tromper cette fois.<br/><br/>Quel câble pensez-vous que je doive sectionner ?'
        }
    }, [timerFrozen])

    const ChatLog = React.useMemo(() => {
        return (
            <div style={{ position: 'absolute', left: 0, top: 0, paddingLeft: '1rem', paddingRight: '1rem' }}>
                { messages.map((m, i) => {
                    return (
                        <div key={`msg-${i}`} className={`message ${ m.you ? 'you' : 'them' }`}>
                            <div className="sender">{ m.you ? 'User:' : 'Claire:'}</div>
                            <div className="content" dangerouslySetInnerHTML={{ __html: m.message }}/>
                        </div>
                    );
                })}
            </div>
        );
    }, [messages])

    const sendMessage = () => {
        handleInput(userInput.current.value);
        userInput.current.value = '';
    }

    const checkIfModule = (input) => {
        return ['réseau', 'alimentation', 'carte mère', 'mémoire'].includes(input)
    }

    const calculateTimerString = (currentTime, offset) => {
      if (offset) currentTime = currentTime - offset
      return `${Math.floor(currentTime/60) }:${ (currentTime % 60 < 10 ? `0${currentTime % 60}` : currentTime % 60)}`
    }

    const timerValue = useMemo(() => {
      return calculateTimerString(timer)
    }, [timer])

    const elapsedTime = (t, offset) => {
        return calculateTimerString((60 * 60) - t, offset)
    }

    const remainingComponentString = useMemo(() => {
        return components.map(a => `<br/>&bull; ${a}`).join('')
    }, [components])

    const handleInput = input => {
        let lowerInput = input.toLowerCase();
        let delay = 2000;

        setMessages([ ...messages, {
            you: true,
            message: input
        }]);

        // Computer Modules
        if (step === 1 || step === 5 || step === 7) {
            setTimeout(() => {
                setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: "Ok, je vais essayer avec ce composant..."
                    }
                ]);
            }, delay / 2)
        }

        // Memory hints
        if (step === 4 || step === 3 || step === 2) {
            setTimeout(() => {
                setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: "Ok, je vais taper ça et voir ce que j’obtiens en retour..."
                    }
                ]);
            }, delay / 2)
        }

        setTimeout(() => {
            if (step === 1) {
                //if (['réseau', 'carte mère', 'alimentation', 'mémoire'].includes(lowerInput)) {

                if (!checkIfModule(lowerInput)) {
                    return setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, je vais essayer avec ce composant..."
                        },
                        {
                            you: false,
                            message: `Je crains que cela ne fasse pas partie de nos options. Essayons encore.<br/><br/>Que pourrions-nous faire ?<br/> ${remainingComponentString}`
                        }
                    ]);
                }

                    if (['réseau', 'carte mère', 'alimentation'].includes(lowerInput)) {
                        setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, je vais essayer avec ce composant..."
                        },
                        {
                            you: false,
                            message: `Oh non ! Ce n’est pas le bon module et le compte à rebours à perdu 5 minutes.<br/><br/>Que pourrions-nous faire ?<br/> ${remainingComponentString}`
                        }
                    ]);

                    setTimePenalty(5 * 60);
                    }

                    if (lowerInput === 'mémoire') {
                        setInputEnabled(false);
                        setComponents(components.filter(a => a !== 'Mémoire'))
                        setSystemInformation("Bonjour, je suis la mémoire système de cet appareil. Il semblerait qu’une partie de ma mémoire ait été corrompue. C’est un plaisir de vous rencontre. Voudriez-vous être mon ami ?");

                        setTimeout(() => {
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: "Ok, je vais essayer avec ce composant..."
                                },
                                {
                                    you: false,
                                    message: "Parfait, cela semble être le meilleur choix, quelque chose semble être apparu sur le panneau d’informations système."
                                }
                            ]);

                            setTimeout(() => {
                                setMessages([ ...messages,
                                    {
                                        you: true,
                                        message: input
                                    },
                                    {
                                        you: false,
                                        message: "Ok, je vais essayer avec ce composant..."
                                    },
                                    {
                                        you: false,
                                        message: "Parfait, cela semble être le meilleur choix, quelque chose semble être apparu sur le panneau d’informations système."
                                    },
                                    {
                                        you: false,
                                        message: "Il semblerait qu’ils aient effacé une grande partie de l’historique de la mémoire système, tout en laissant une espèce d programme d’intelligence artificielle… C’est frustrant."
                                    }
                                ]);

                                setTimeout(() => {
                                    setSystemInformation("Je n’ai que très peu d’occasions de parler avec des gens. Laissez-moi vous raconter une histoire.");
                                    setTimeout(() => {
                                        setMessages([ ...messages,
                                            {
                                                you: true,
                                                message: input
                                            },
                                            {
                                                you: false,
                                                message: "Ok, je vais essayer avec ce composant..."
                                            },
                                            {
                                                you: false,
                                                message: "Parfait, cela semble être le meilleur choix, quelque chose semble être apparu sur le panneau d’informations système."
                                            },
                                            {
                                                you: false,
                                                message: "Il semblerait qu’ils aient effacé une grande partie de l’historique de la mémoire système, tout en laissant une espèce d programme d’intelligence artificielle… C’est frustrant."
                                            },
                                            {
                                                you: false,
                                                message: "Je suis sûr que quelque chose dans l’historique peut nous aider à tout désactiver. Essayez de voir si vous pouvez récupérer certains des fichiers qui ont été effacés…"
                                            }
                                        ]);

                                        setTimeout(() => {
                                            setSystemInformation("Oh, apparemment toutes les histoires que je connaissais ont été effacées de ma mémoire.");
                                            setTimeout(() => {
                                                setMessages([ ...messages,
                                                {
                                                    you: true,
                                                    message: input
                                                },
                                                {
                                                    you: false,
                                                    message: "Ok, je vais essayer avec ce composant..."
                                                },
                                                {
                                                    you: false,
                                                    message: "Parfait, cela semble être le meilleur choix, quelque chose semble être apparu sur le panneau d’informations système."
                                                },
                                                {
                                                    you: false,
                                                    message: "Il semblerait qu’ils aient effacé une grande partie de l’historique de la mémoire système, tout en laissant une espèce d programme d’intelligence artificielle… C’est frustrant."
                                                },
                                                {
                                                    you: false,
                                                    message: "Je suis sûr que quelque chose dans l’historique peut nous aider à tout désactiver. Essayez de voir si vous pouvez récupérer certains des fichiers qui ont été effacés..."
                                                },
                                                {
                                                    you: false,
                                                    message: "Esssayons de trouver un mot-clé qui nous permettrait de restaurer les mémoires systèmes. Je suis sûr qu’il y a quelque chose d’important dissimulé dans ce code. Peut-être qu’en en découvrant plus sur ce code, nous trouverons un moyen de tout désactiver une bonne fois pour toutes. Nous devrions travailler à partir des morceaux de mémoire les plus récents et continuer à rebours.<br/><br/> - Envoyez-moi le mot qui selon vous pourrait m’aider."
                                                }
                                                ]);
                                                setInputEnabled(true);
                                                setStep(2);
                                            }, delay);
                                        }, 4000);
                                    }, delay);
                                }, delay);
                            }, delay)
                        }, delay);
                    }
                // }
                //else
                //{
                    //setMessages([ ...messages,
                        //{
                            //you: true,
                            //message: input
                       // },
                        //{
                           // you: false,
                           // message: "Je crains que cela ne fasse pas partie de nos options. Essayons encore.<br/><br/>Que pourrions-nous faire ?<br/><br/> &bull; Réseau <br/> &bull; Mémoire <br/> &bull; Carte mère <br/> &bull; Alimentation"
                       // }
               // }
            }

            if (step === 2) {
                if (lowerInput === 'saucisses') {
                    setSystemInformation('Oh mon Dieu, tout me revient maintenant. L’empoisonnement des saucisses durant les pourparlers. Trois personnes décédées… et je… c’est ma faute… oh mon Dieu. Quelles autres atrocités ai-je bien pu perpétrer ?');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, je vais taper ça et voir ce que j’obtiens en retour..."
                        },
                        {
                            you: false,
                            message: 'Bon travail ! Apparemment le système veut en apprendre plus sur lui-même. Essayons de trouver une autre mot-clé pour rafraîchir la mémoire de cette machine. <br/><br/> - Envoyez-moi le mot qui selon vous pourrait m’aider.'
                        }
                    ]);
                    setStep(3);
                } else {
                    setSystemInformation('Qu’est-ce que vous racontez ? Vous, les humains, vous êtes tellement bizarres...');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, je vais taper ça et voir ce que j’obtiens en retour."
                        },
                        {
                            you: false,
                            message: "Apparemment ce n’est pas le bon mot, il faudrait essayer quelque chose de différent.<br/><br/> - Envoyez-moi le mot qui selon vous pourrait m’aider."
                        }
                    ]);
                }
            }

            if (step === 3) {
                if (lowerInput === 'yémen') {
                    setSystemInformation('Tout ces gens… couverts de pétrole… et en feu… Qu’est-ce que j’ai fait… N’ai-je jamais été impliqué que dans des incidents ? C’est presque insupportable, mais il faut que je me rappelle...');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, je vais taper ça et voir ce que j’obtiens en retour."
                        },
                        {
                            you: false,
                            message: 'Elle veut en savoir plus. S’adresser à une IA, ça fait bizarre. Mais nous sommes arrivés jusqu’ici, alors autant continuer.<br/><br/> - Envoyez-moi le mot qui selon vous pourrait m’aider.'
                        }
                    ]);
                    setStep(4);
                } else {
                    setSystemInformation('Qu’est-ce que vous racontez ? Vous, les humains, vous êtes tellement bizarres...');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, je vais taper ça et voir ce que j’obtiens en retour."
                        },
                        {
                            you: false,
                            message: "Apparemment ce n’est pas le bon mot, il faudrait essayer quelque chose de différent. <br/><br/> - Envoyez-moi le mot qui selon vous pourrait m’aider."
                        }
                    ]);
                }
            }

            if (step === 4) {
                if (lowerInput === 'platypus') {
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                    ]);
                    setInputEnabled(false);
                    setSystemInformation('Platypus. Platy… pus… Je ne pensais pas qu’il pouvait y avoir pire que le souvenir de tous ces gens morts au Yémen. Mais la destruction insensée de ces horloges à coucou, c’en est trop. Je ne peux le supporter. Il faut m’arrêter. Je dois arrêter tout ça...');
                    setTimeout(() => {
                        setSystemInformation('--- AUTODESTRUCTION DE LA MEMOIRE PROGRAMMEE ---');
                        setTimeout(() => {
                            setSystemInformation('--- Système mémoire désactivé ---');
                            setImage(NetworkMotherboardPower);
                            setTimeout(() => {
                                setSystemInformation("");
                                setMessages([ ...messages,
                                    {
                                        you: true,
                                        message: input
                                    },
                                    {
                                        you: false,
                                        message: "Ok, je vais taper ça et voir ce que j’obtiens en retour."
                                    },
                                    {
                                        you: false,
                                        message: `Okayyy… Voilà une chose peu banale. Mais au moins nous avons réussi à désactiver un des composants. Plus que trois.<br/><br/> Quel composant devrions-nous désactiver ensuite ? <br/> ${remainingComponentString}`
                                    },
                                ]);
                                setInputEnabled(true);
                                setStep(5);
                            }, 4000);
                        }, 4000)
                    }, 8000)
                } else {
                    setSystemInformation('Qu’est-ce que vous racontez ? Vous, les humains, vous êtes tellement bizarres...');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, je vais taper ça et voir ce que j’obtiens en retour."
                        },
                        {
                            you: false,
                            message: "Apparemment ce n’est pas le bon mot, il faudrait essayer quelque chose de différent. <br/><br/> - Envoyez-moi le mot qui selon vous pourrait m’aider."
                        }
                    ]);
                }
            }

            if (step === 5) {

                if (!checkIfModule(lowerInput)) {
                    return setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, je vais essayer avec ce composant."
                        },
                        {
                            you: false,
                            message: `Je crains que cela ne fasse pas partie de nos options. Essayons encore.<br/><br/>Que pourrions-nous faire ? <br/> ${remainingComponentString}`
                        }
                    ]);
                }

                if (['carte mère', 'alimentation'].includes(lowerInput)) {
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, je vais essayer avec ce composant."
                        },
                        {
                            you: false,
                            message: `Oh non ! Ce n’est pas le bon module et le compte à rebours à perdu 5 minutes.<br/><br/> Quel composant devrions-nous désactiver ensuite ? <br/> ${remainingComponentString}`
                        }
                    ]);

                    setTimePenalty(5 * 60);
                }

                if (lowerInput === 'réseau') {
                    setInputEnabled(false);

                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        }
                    ]);

                    setSystemInformation("Le réseau de la station d’épuration Z8.\n\n Chaque nœud de ce réseau est relié à deux autres nœuds, ce qui crée une boucle dans laquelle se trouvent tous les nœuds du réseau. Chaque nœud ne peut être connecté qu’à un autre nœud avec lequel il partage au moins une partie du nom (quelle que soit sa position), mais ne peut être connecté à deux nœuds qui partagent une même partie de nom (par exemple, le nœud A1B pour se connecter soit à A2C ou à D3A, mais pas aux deux).");

                    setTimeout(() => {
                        setMessages([...messages,
                            {
                                you: true,
                                message: input
                            },
                            {
                                you: false,
                                message: "Ok, je vais essayer avec ce composant."
                            },
                            {
                                you: false,
                                message: 'Parfait, il semblerait que ce soit le bon choix. Il me semble judicieux de déconnecter l’appareil du réseau wi-fi. Ainsi, Yakov ne pourra rien faire à distance.<br/><br/>Quelque chose semble de nouveau être apparu sur le panneau d’informations système'
                            }
                        ]);

                        setTimeout(() => {
                            setMessages([...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: "Ok, je vais essayer avec ce composant."
                                },
                                {
                                    you: false,
                                    message: 'Parfait, il semblerait que ce soit le bon choix. Il me semble judicieux de déconnecter l’appareil du réseau wi-fi. Ainsi, Yakov ne pourra rien faire à distance.<br/><br/>Quelque chose semble de nouveau être apparu sur le panneau d’informations système'
                                },
                                {
                                    you: false,
                                    message: 'Il faut que nous trouvions les deux nœuds qui sont connectés au nœud du réseau de l’appareil. Une fois que je saurai de quels nœuds il s’agit, je pourrai les désactiver.<br/><br/>Quand vous saurez de quels nœuds il s’agit, tapez-les tous les deux en même temps, séparés par une virgule.'
                                }
                            ]);
                            setComponents(components.filter(a => a !== 'Réseau'))
                            setInputEnabled(true);
                            setStep(6);
                        })
                    }, 3000)
                }
            }

            if (step === 6) {
                const correct = ["K5C", "B4E"];
                const validButIncorrect = ["C3B", "B4E", "D4A", "1F4", "A2G", "G6J", "B7D", "8F2", "6J2", "K5C"];
                const valid = correct.concat(validButIncorrect)
                let hasInvalid = false

                console.log('Valid', valid)

                let values = lowerInput.split(',');

                const onlyUnique = (value, index, self) => {
                    return self.indexOf(value) === index;
                }

                values = values.map((i, index) => {
                    return i.trim();
                });

                values = values.filter(onlyUnique); // You can't cheat by using one correct answer 3 times.

                values.forEach(val => {
                    if (!valid.includes(val.toUpperCase())) hasInvalid = true
                })

                if (hasInvalid) {
                    return setMessages([
                        ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Il faut que nous trouvions les deux nœuds qui sont connectés au nœud du réseau de l’appareil. Une fois que je saurai de quels nœuds il s’agit, je pourrai les désactiver."
                        }
                    ])
                }

                if (values.length === 2 // Prevents errors from undefined indexes.
                    &&
                    correct.includes(values[0].toUpperCase())
                    &&
                    correct.includes(values[1].toUpperCase())
                    // &&
                    // correct.includes(values[2].toUpperCase())
                ) {
                    setInputEnabled(false);
                    setMessages([...messages,
                        {
                            you: true,
                            message: input
                        }
                    ]);
                    setTimeout(() => {
                        setSystemInformation('--- Système réseau désactivé ---');
                        setImage(MotherboardPower);
                        setTimeout(() => {
                            setSystemInformation('');
                            setMessages([...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: 'Super, ça a fonctionné ! Désormais, Yakov ne peut plus faire fonctionner l’appareil à distance.'
                                }
                            ]);
                            setTimeout(() => {
                                setMessages([...messages,
                                    {
                                        you: true,
                                        message: input
                                    },
                                    {
                                        you: false,
                                        message: 'Super, ça a fonctionné ! Désormais, Yakov ne peut plus faire fonctionner l’appareil à distance.'
                                    },
                                    {
                                        you: false,
                                        message: `Passons à la section suivante. Que faire ensuite ? <br/> ${remainingComponentString}`
                                    }
                                ]);
                                setStep(7);
                                setInputEnabled(true);
                            }, delay);
                        }, delay);
                    }, delay);

                } else {
                    setInputEnabled(false);
                    setMessages([...messages,
                        {
                            you: true,
                            message: input
                        }
                    ]);

                    setTimeout(() => {
                        setMessages([...messages,
                            {
                                you: true,
                                message: input
                            },
                            {
                                you: false,
                                message: 'Hmm, j’ai essayé de désactiver ces deux nœuds, mais l’appareil semble toujours connecté au réseau. Je vais les réactiver pour n’attirer aucun soupçon.<br/><br/>Concentrez-vous bien et dites-moi quels sont les deux nœuds que je dois désactiver.'
                            }
                        ]);
                        setInputEnabled(true);
                    }, delay);
                }
            }

            if (step === 7) {

                if (!checkIfModule(lowerInput)) {
                    return setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, je vais essayer avec ce composant."
                        },
                        {
                            you: false,
                            message: `Je crains que cela ne fasse pas partie de nos options. Essayons encore.<br/><br/> Que pourrions-nous faire ? <br/> ${remainingComponentString}`
                        }
                    ]);
                }

                if (lowerInput === 'alimentation') {
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, je vais essayer avec ce composant."
                        },
                        {
                            you: false,
                            message: `Oh non ! Ce n’est pas le bon module et le compte à rebours à perdu 5 minutes.<br/><br/>Que pourrions-nous faire ?<br/> ${remainingComponentString}`
                        }
                    ]);

                    setTimePenalty(5 * 60);
                }

                if (lowerInput === 'carte mère') {
                    setInputEnabled(false);

                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        }
                    ]);

                    setSystemInformation('Prêt pour réception commandes. Capacité de réception : deux commandes en même temps. \n \n Température interne : 27 degrés.');

                    setTimeout(() => {
                        setMessages([ ...messages,
                            {
                                you: true,
                                message: input
                            },
                            {
                                you: false,
                                message: "Ok, je vais essayer avec ce composant."
                            },
                            {
                                you: false,
                                message: 'Apparemment, la carte mère de ce bidule peut gérer deux commandes à la fois.<br/><br/> Peut-être qu’on peut utiliser cette information à notre avantage et trouver deux commandes qui pourraient désactiver la carte mère... <br/><br/> Tapez-les ci-dessous, séparées par une virgule, et je les entrerai ensuite dans la machine.'
                            }
                        ]);
                        setComponents(components.filter(a => a !== 'Carte mère'))
                        setStep(8);
                        setInputEnabled(true);
                    }, delay);
                }
            }

            if (step === 8) {
                let commands = lowerInput.split(',');
                commands = commands.map((v, i) => {
                    return v.trim();
                });

                let validCommands = {
                    'cuire pancakes': 42,
                    'ouvrir porte garage': 9,
                    'allumer lampes': 13,
                    'aspirer sol': 37,
                    'sécher mains': 17,
                    'griller pain': 28
                }

                if (commands.length === 2
                    &&
                    validCommands[commands[0]]
                    &&
                    validCommands[commands[1]]
                    //&&
                    //validCommands[commands[2]]
                ) {
                    setInputEnabled(false);
                    setMessages([
                        ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "D’accord, je vais essayer ces deux-là. Donnez-moi un instant."
                        }
                    ]);

                    //let temp = 27 + validCommands[commands[0]] + validCommands[commands[1]] + validCommands[commands[2]];

                    let temp = 27 + validCommands[commands[0]] + validCommands[commands[1]];

                    if (temp !== 77) {
                        setTimeout(() => {
                            setSystemInformation(`${input}\n\nTempérature interne : ${temp} degrés`);
                            setTimeout(() => {
                                setSystemInformation(`${input}\n\nTempérature interne : ${temp} degrés\n\nCarte mère stable.`);
                                setMessages([
                                    ...messages,
                                    {
                                        you: true,
                                        message: input.split('\n').join('<br/>')
                                    },
                                    {
                                        you: false,
                                        message: "D’accord, je vais essayer ces deux-là. Donnez-moi un instant."
                                    },
                                    {
                                        you: false,
                                        message: `Apparemment ces deux commandes on fait augmenter la température de ${temp} degrés. Mais il semblerait qu’elle soit retombée à 27 degrés maintenant.<br/><br/>Essayons deux autres commandes.`
                                    }
                                ]);
                                setTimeout(() => {
                                    setSystemInformation('Prêt pour réception commandes. Capacité de réception : deux commandes en même temps. \n \n Température interne : 27 degrés.');
                                    setInputEnabled(true);
                                }, delay);
                            }, delay);
                        }, delay);
                    } else {
                        setTimeout(() => {
                            setSystemInformation(`${input}\n\nTempérature interne : ${temp} degrés`);
                            setTimeout(() => {
                                setSystemInformation(`${input}\n\nTempérature interne : ${temp} degrés\n\n--- TEMPERATURE CRITIQUE ---`);
                                setTimeout(() => {
                                    setSystemInformation("--- Carte mère désactivée ---");
                                    setImage(Power);
                                    setTimeout(() => {
                                        setSystemInformation("");
                                        setTimeout(() => {
                                            setMessages([
                                                ...messages,
                                                {
                                                    you: true,
                                                    message: input.split('\n').join('<br/>')
                                                },
                                                {
                                                    you: false,
                                                    message: "D’accord, je vais essayer ces deux-là. Donnez-moi un instant."
                                                },
                                                {
                                                    you: false,
                                                    message: "Génial, nous avons réussi à détruire la carte mère.<br/><br/>Dernier composant désormais : l’alimentation."
                                                }
                                            ]);
                                            setTimeout(() => {
                                                setSystemInformation("Première couche de câbles : \n Rouge, vert, bleu, noir, rose.");
                                                setTimeout(() => {
                                                    setMessages([
                                                        ...messages,
                                                        {
                                                            you: true,
                                                            message: input.split('\n').join('<br/>')
                                                        },
                                                        {
                                                            you: false,
                                                            message: "D’accord, je vais essayer ces deux-là. Donnez-moi un instant."
                                                        },
                                                        {
                                                            you: false,
                                                            message: "Génial, nous avons réussi à détruire la carte mère.<br/><br/>Dernier composant désormais : l’alimentation."
                                                        },
                                                        {
                                                            you: false,
                                                            message: "Apparemment, il y a plusieurs couches de câbles. Je pense qu’il faut sectionner le bon câble de chaque couche dans le bon ordre pour désactiver l’alimentation, et donc toute la machinerie. Essayez de trouver quels câbles je dois sectionner."
                                                        }
                                                    ]);
                                                    setStep(9);
                                                    setInputEnabled(true);
                                                }, delay);
                                            }, delay);
                                        }, delay);
                                    }, delay)
                                }, delay)
                            }, delay)
                        }, delay)
                    }
                } else {
                    if (commands.length !== 2) {
                        setMessages([ ...messages,
                            {
                                you: true,
                                message: input.split('\n').join('<br/>')
                            },
                            {
                                you: false,
                                message: "Apparemment, la carte mère de ce bidule peut gérer deux commandes à la fois.<br/><br/>Type them below - separated by a comma - and I'll enter them into the machine."
                            }
                        ]);
                    } else {
                        setMessages([ ...messages,
                            {
                                you: true,
                                message: input.split('\n').join('<br/>')
                            },
                            {
                                you: false,
                                message: "J’ai essayé ces deux commandes, mais apparemment au moins l’une des deux était incorrecte.<br/><br/>Assurez-vous d’essayer des commandes que le terminal comprenne.<br/><br/><br/>Essayons deux autres commandes. "
                            }
                        ]);
                    }
                }
            }

            if (step === 9) {
                if (lowerInput === 'rouge') {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, je vais sectionner le câble ${lowerInput}, c’est parti !`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Première couche de câbles : \n Rouge, vert, bleu, noir, rose.\n\n--- COUCHE COURT-CIRCUITEE ---");
                        setTimeout(() => {
                            setSystemInformation("Deuxième couche de câbles : \n Rose, jaune, bleu");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, je vais sectionner le câble ${lowerInput}, c’est parti !`
                                },
                                {
                                    you: false,
                                    message: "Ça a l’air correct. Passons à la couche suivante. <br/><br/> Quel câble dois-je sectionner cette fois-ci ?"
                                }
                            ]);
                            setStep(10);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else if (['vert', 'noir', 'bleu', 'rose'].includes(lowerInput)) {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, je vais sectionner le câble ${lowerInput}, c’est parti !`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Première couche de câbles : \n Rouge, vert, bleu, noir, rose.\n\n--- SABOTAGE DETECTE ! ---\n\n");
                        setTimeout(() => {
                            setSystemInformation("Première couche de câbles : \n Rouge, vert, bleu, noir, rose.");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, je vais sectionner le câble ${lowerInput}, c’est parti !`
                                },
                                {
                                    you: false,
                                    message: "Oh non, apparemment, il s’agissait du mauvais câble… et le compte à rebours s’est accéléré. Réessayons, sans nous tromper cette fois.<br/><br/>Quel câble pensez-vous que je doive sectionner ?"
                                }
                            ]);
                            setTimerInterval(timerInterval * 0.6);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else {
                  setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: `Non, je ne pense pas que ce soit ça. Restez concentré !`
                    }
                  ]);
                }
            }

            if (step === 10) {
                if (lowerInput === 'bleu') {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, je vais sectionner le câble ${lowerInput}, c’est parti !`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Deuxième couche de câbles : \n Rose, jaune, bleu\n\n--- COUCHE COURT-CIRCUITEE ---");
                        setTimeout(() => {
                            setSystemInformation("Troisième couche de câbles : \n Rose, noir, bleu, jaune");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, je vais sectionner le câble ${lowerInput}, c’est parti !`
                                },
                                {
                                    you: false,
                                    message: "Ça a l’air correct. Passons à la couche suivante. <br/><br/> Quel câble dois-je sectionner cette fois-ci ?"
                                }
                            ]);
                            setStep(11);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else if (['jaune', 'rose'].includes(lowerInput)) {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, je vais sectionner le câble ${lowerInput}, c’est parti !`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Deuxième couche de câbles : \n Rose, jaune, bleu\n\n--- SABOTAGE DETECTE ! ---");
                        setTimeout(() => {
                            setSystemInformation("Deuxième couche de câbles : \n Rose, jaune, bleu");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, je vais sectionner le câble ${lowerInput}, c’est parti !`
                                },
                                {
                                    you: false,
                                    message: wireCutFailMessage
                                }
                            ]);
                            setTimerInterval(timerInterval * 0.6);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else {
                  setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: `Non, je ne pense pas que ce soit ça. Restez concentré !`
                    }
                  ]);
                }
            }

            if (step === 11) {
                if (lowerInput === 'rose') {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, je vais sectionner le câble ${lowerInput}, c’est parti !`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Troisième couche de câbles : \n Rose, noir, bleu, jaune\n\n--- COUCHE COURT-CIRCUITEE ---");
                        setTimeout(() => {
                            setSystemInformation("Quatrième couche de câbles :  \n Vert, rose, bleu, rouge, jaune");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, je vais sectionner le câble ${lowerInput}, c’est parti !`
                                },
                                {
                                    you: false,
                                    message: "Ça a l’air correct. Passons à la couche suivante.<br/><br/>Quel câble dois-je sectionner cette fois-ci ?"
                                }
                            ]);
                            setStep(12);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else if (['jaune', 'noir', 'bleu'].includes(lowerInput)) {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, je vais sectionner le câble ${lowerInput}, c’est parti !`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Troisième couche de câbles : \n Rose, noir, bleu, jaune\n\n--- SABOTAGE DETECTE ! ---");
                        setTimeout(() => {
                            setSystemInformation("Troisième couche de câbles : \n Rose, noir, bleu, jaune");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, je vais sectionner le câble ${lowerInput}, c’est parti !`
                                },
                                {
                                    you: false,
                                    message: wireCutFailMessage
                                }
                            ]);
                            setTimerInterval(timerInterval * 0.6);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else {
                  setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: `Non, je ne pense pas que ce soit ça. Restez concentré !`
                    }
                  ]);
                }
            }

            if (step === 12) {
                if (lowerInput === 'bleu') {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, je vais sectionner le câble ${lowerInput}, c’est parti !`
                        }
                    ]);

                    setStopTimer(true);
                    setTimeout(() => {
                        setSystemInformation("Quatrième couche de câbles :  \n Vert, rose, bleu, rouge, jaune\n\n--- COUCHE COURT-CIRCUITEE ---");
                        setTimeout(() => {
                            setSystemInformation("--- Alimentation désactivée ---");
                            setImage(AllDisabled);
                            setTimeout(() => {
                                setSystemInformation(`--- SYSTÈME ENTIÈREMENT DÉSACTIVÉ ---\n\n--- COMPTE À REBOURS ARRÊTÉ À : ${timerFrozen ? '60:00+' : calculateTimerString(timer, 2)} ---`);
                                setImage(Defused);
                                setTimeout(() => {
                                    if (!timerFrozen) {
                                        setMessages([ ...messages,
                                            {
                                                you: true,
                                                message: input
                                            },
                                            {
                                                you: false,
                                                message: `Ok, je vais sectionner le câble ${lowerInput}, c’est parti !`
                                            },
                                            {
                                                you: false,
                                                message: `Merveilleux, nous avons désactivé tous les composants ! Excellent travail ! Les Molvariens sont désormais sauvés des horreurs que Yakov avait l’intention de leur infliger. Bon, je pense qu’il nous reste suffisamment de temps pour le trouver ! Pendant que vous étiez en train de désarmer l’appareil, j’ai effectué quelques recherches pour tenter de le retrouver.<a href='http://www.conspiralist.com/message-from-claire-1-fr/?playerTime=${ image === Defused ? '45:00 +' : elapsedTime(timer, 0)}'>Cliquez</a> vite ici pour accéder aux informations que j’ai pu recueillir. `
                                            }
                                        ]);
                                    } else {
                                        setMessages([ ...messages,
                                            {
                                                you: true,
                                                message: input
                                            },
                                            {
                                                you: false,
                                                message: `Ok, je vais sectionner le câble ${lowerInput}, c’est parti !`
                                            },
                                            {
                                                you: false,
                                                message: "Merveilleux, nous avons désactivé tous les composants ! Excellent travail ! Les Molvariens sont désormais sauvés des horreurs que Yakov avait l’intention de leur infliger. Unfortunately, I think that whilst we were disarming the device he will have been able to escape. But that's ok, we stopped his plan all the same. The next step is to release the news about what the government was up to. I was working on some things whilst you were disarming the device. <a href='http://www.conspiralist.com/message-from-claire-2-fr/?playerTime=60:00+'>Click here</a> to see our next steps."
                                            }
                                        ]);
                                    }
                                }, delay);
                            }, delay);
                        }, delay);
                    }, delay);
                } else if (['rouge', 'rose', 'vert', 'jaune'].includes(lowerInput)) {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, je vais sectionner le câble ${lowerInput}, c’est parti !`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Quatrième couche de câbles : \n Vert, rose, bleu, rouge, jaune\n\n--- SABOTAGE DETECTE ! ---\n\n");
                        setTimeout(() => {
                            setSystemInformation("Quatrième couche de câbles : \n Vert, rose, bleu, rouge, jaune");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, je vais sectionner le câble ${lowerInput}, c’est parti !`
                                },
                                {
                                    you: false,
                                    message: "Oh non, apparemment, il s’agissait du mauvais câble… et le compte à rebours s’est accéléré. Réessayons, sans nous tromper cette fois.<br/><br/>Quel câble dois-je sectionner cette fois-ci ?"
                                }
                            ]);
                            setTimerInterval(timerInterval * 0.6);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else {
                  setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: `Non, je ne pense pas que ce soit ça. Restez concentré !`
                    }
                  ]);
                }
            }
        }, delay);
    }

    const TimerFunction = React.useCallback(() => {
        if (!stopTimer) {
            if ((timer - (1 + timePenalty)) < 1 && !timerFrozen) {
                console.log(timerFrozen);
                setTimerFrozen(true);
                setError(true);
            } else {
                timerDispatch();
                setTimePenalty(0);
            }
        }
    }, [stopTimer, timer, timerFrozen, timerDispatch, setError, timePenalty]);

    React.useEffect(() => {
        var clock = null;

        const resetTimeout = () => {
            clock = setTimeout(TimerFunction, timerInterval * 1000);
        }

        console.log('resetTimer');
        resetTimeout();

        return () => {
            clearTimeout(clock);
        }
    }, [timerInterval, TimerFunction, initialState]);

    React.useEffect(() => {
        document.querySelector('#chat-box').scrollTop = document.querySelector('#chat-box').scrollHeight;
    }, [ChatLog]);

    return (
        <div className="main-game">
            <Panel variant='outside' shadow style={{ width: '100%', height: '100%', padding: '1rem' }} className="left-panel">
                <Fieldset>
                    <div className="timer">
                        <p>Temps avant le déversement :</p>
                        <p style={{ color: timer < 300 ? 'red' : 'black' }}>{ timerFrozen ? 'FROZEN' : `${ timerValue }` }</p>
                    </div>
                </Fieldset>
                <Fieldset label="Informations système">
                    <TextField
                        value={ systemInformation }
                        placeholder='En attente d’instructions...'
                        onChange={(e) => {}}
                        fullWidth
                        multiline
                        style={{ height: '100%', minHeight: 166 }}
                    />
                </Fieldset>

                <Fieldset label="Diagnostiques des appareils" style={{ maxHeight: 150 }}>
                    <div className="gif-panel">
                        <div className="content">
                            <img src={image} alt="" style={{ height: '100%', width: '100%' }}/>
                        </div>
                    </div>
                </Fieldset>
            </Panel>
            <Panel variant='outside' shadow style={{ width: '100%', height: '100%', padding: '1rem' }} className="right-panel">
                <Fieldset label="Z8 WTP Internal Chat:">
                    <div className="chat-section">
                        <TextField
                            value=""
                            placeholder='*** NE PEUT ETRE UTILISE QUE PAR LES EMPLOYES ***'
                            onChange={(e) => {}}
                            fullWidth
                            className="internal-use-box"
                        />
                        <div id="chat-box" className="gif-panel chatlog no-before" style={{ overflowY: 'scroll', height: `${ 330 - (25 * (rows - 1))}px`, scrollPaddingTop: '1rem' }}>
                            { ChatLog }
                        </div>
                        <Fieldset className="input-box">
                            <TextField
                                ref={userInput}
                                // value={inputText}
                                placeholder="Commencez à taper ici.."
                                // onChange={debounce((e) => { setInputText(e.target.value); console.log('Changed', e.target.value) }, 1000)}
                                onKeyDown={ handleKeyDown }
                                enabled={ inputEnabled }
                                fullWidth
                                multiline={rows !== 1}
                                rows={rows}
                            />
                            <Button onClick={ sendMessage } disabled={!inputEnabled} style={{ height: '100%' }}>
                                Envoyer
                            </Button>
                        </Fieldset>
                    </div>
                </Fieldset>
            </Panel>
        </div>
    )
}
