import React from 'react';
import {
    Panel, Progress
} from 'react95';
import Play from '../Play/Play';
import './game.scss';

export default function Game ({ setError, ...props }) {
    const [loaded, setLoaded] = React.useState(true);
    const [initialState] = React.useState({
        timer: JSON.parse(localStorage.getItem('timer')),
        messages: JSON.parse(localStorage.getItem('messages')),
        inputText: JSON.parse(localStorage.getItem('inputText')),
        step: JSON.parse(localStorage.getItem('step')),
        inputEnabled: JSON.parse(localStorage.getItem('inputEnabled')),
        systemInformation: JSON.parse(localStorage.getItem('systemInformation')),
        timerInterval: JSON.parse(localStorage.getItem('timerInterval')),
        stopTimer: JSON.parse(localStorage.getItem('stopTimer')),
        timerFrozen: JSON.parse(localStorage.getItem('timerFrozen')),
        image: JSON.parse(localStorage.getItem('image')),
        shouldLoad: JSON.parse(localStorage.getItem('shouldLoad')),
    });

    return (
        <>
            { !loaded ?
                <Loader setLoaded={setLoaded}/>
            :
                <Play setError={setError} initialState={initialState}/>
            }
        </>
    )
}

export function Loader ({ setLoaded, ...props }) {
    const [loadProgress, setLoadProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setLoadProgress(current => {
                if (current === 100) {
                    setLoaded(true);
                    return 100;
                }

                const diff = Math.random() * 10;
                return Math.min(current + diff, 100);
            })
        }, 500);

        return () => {
            clearInterval(timer);
        }
    }, [setLoadProgress, setLoaded]);

    return (
        <Panel variant='well' shadow style={{ width: '100%', height: '100%', padding: '2rem' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',  height: '100%' }}>
                <p>Loading...</p>
                <Progress variant="tile" value={loadProgress}/>
            </div>
        </Panel>
    )
}
